import React from "react"
import { Heading, Text } from "@atoms"
import { Link } from "gatsby"
import { InlineLink } from "../../../Resources/ResourceCardInlineLink"
import {
  FeaturedItemWrapper,
  Content,
  FeaturedText,
  FeaturedPhoto,
} from "../styles/HeroFeaturedPost.styled"
import { getCardSlug } from "../../../../utils/getCardSlug"

import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import OptimizedImage from "../../../../utils/optimizedImage"

const HeroFeaturedPost = ({ item }) => {
  let cardSlug = getCardSlug(item)
  let category = item?.category[0]?.title.toLowerCase()
  let cardImage = item?.infographicImage ?? item?.featuredImage
  let pdfEmbedUrl = item?.pdfFile?.url

  return (
    <FeaturedItemWrapper href={cardSlug}>
      <Link to={cardSlug}>
        <Content>
          <FeaturedText>
            {category && (
              <p
                className="item-category"
                style={{
                  marginBottom: "12px",
                  backgroundColor: item?.category[0]?.backgroundColor || "#ddd",
                  color: item?.category[0]?.fontColor || "#1b1b1b",
                }}
              >
                {category}
              </p>
            )}
            {item?.title && (
              <Heading
                headingSize="H3"
                background="light"
                className="item-title"
              >
                {item?.title}
              </Heading>
            )}
            {item?.description && (
              <Text textVariant="dark" className="item-description">
                {item?.description?.description}
              </Text>
            )}
            <div
              style={{
                marginTop: "auto",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              {category && (
                <InlineLink
                  category={
                    category === "guide" && pdfEmbedUrl ? "guides" : category
                  }
                />
              )}
            </div>
          </FeaturedText>
          {cardImage && (
            <FeaturedPhoto>
              <OptimizedImage
                image={cardImage?.gatsbyImageData}
                src={cardImage?.file?.url}
                alt={cardImage?.file?.alt || "Featured Image"}
              />
            </FeaturedPhoto>
          )}
        </Content>
      </Link>
    </FeaturedItemWrapper>
  )
}

export default HeroFeaturedPost
