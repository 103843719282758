import React, {
  useState,
  useMemo,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react"
import { Card } from "@components/Card"
import styled from "styled-components"
import { theme } from "@theme"
import { ConversionPanel } from "@components/ConversionPanel"
import { Button } from "@atoms"
import { getCardSlug } from "../../utils/getCardSlug"
import ComponentPagination from "../Pagination/Pagination"
import { paginator } from "../../utils/paginatorFunction"
import { pushParams } from "../../utils/pushParams"
import queryString from "query-string"
import CustomSelect from "../Select"

const InsetComponent = ({ paginatedPosts, component, twoCols, oneCol }) => {
  if (twoCols || oneCol || paginatedPosts.length > 12) {
    return (
      <div className="inset">
        <ConversionPanel component={component} />
      </div>
    )
  }
  return null
}

export const ResourceHubCardDeck = ({
  resources,
  categories,
  insetComponent,
}) => {
  const [showScrollToTop, setShowScrollToTop] = useState(false)
  const [selectedOption, setSelectedOption] = useState({
    value: "All",
    label: "All",
  })
  const [sortedResources, setSortedResources] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [twoCols, setTwoCols] = useState(null)
  const [oneCol, setOneCol] = useState(null)
  const [maxPostsPerPage, setMaxPosts] = useState(24)
  const [filterParams, setFilterParams] = useState("")

  useEffect(() => {
    if (resources.length > 0) {
      setSortedResources(resources)
    }
  }, [resources])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = queryString.parse(window.location.search)

      let updatedFilters
      const parseParams = params => {
        if (params?.category) {
          updatedFilters = {
            value: params?.category,
            label: params?.category,
          }
        } else {
          updatedFilters = {
            value: "All",
            label: "All",
          }
        }
      }

      parseParams(params)
      setSelectedOption(updatedFilters)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 1000) {
        setShowScrollToTop(true)
      } else {
        setShowScrollToTop(false)
      }
    })
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    })
  }

  let paginatedPostData = paginator(
    sortedResources,
    currentPage,
    maxPostsPerPage
  )

  let paginatedPosts = useMemo(
    () =>
      paginatedPostData.data.map(resource => {
        let cardSlug = getCardSlug(resource)
        let infographicImage = resource?.node?.infographicImage?.file?.url
        let infographicGatsbyImage =
          resource?.node?.infographicImage?.gatsbyImageData

        return (
          <Card
            gatsbyImage={
              infographicGatsbyImage ||
              resource?.node?.featuredImage?.gatsbyImageData
            }
            title={resource?.node?.title}
            featuredImg={
              infographicImage || resource?.node?.featuredImage?.file.url
            }
            description={resource?.node?.description?.description}
            categories={resource?.node?.category}
            link={cardSlug}
            pdfEmbedLink={resource?.node?.pdfFile?.url}
            border={"#E4E7EC"}
            type={"resource"}
          />
        )
      }),
    [paginatedPostData]
  )

  const selectCompOptions = categories?.sort().reduce(
    (finalArr, initialCategory) => {
      finalArr.push({
        value: `${initialCategory}`,
        label: `${initialCategory}`,
      })
      return finalArr
    },
    [{ value: "All", label: "All" }]
  )

  const updateCols = () => {
    setTwoCols(window.innerWidth < 1080 && window.innerWidth > 768)
    setOneCol(window.innerWidth < 768)
  }
  const updatePaginator = () => {
    if (window.innerWidth < 1080 && window.innerWidth > 768) {
      setMaxPosts(12)
    } else if (window.innerWidth < 768) {
      setMaxPosts(8)
    } else {
      setMaxPosts(24)
    }
  }
  useEffect(() => {
    updateCols()
    updatePaginator()
    window.addEventListener("resize", updateCols, updatePaginator)
    return () =>
      window.removeEventListener("resize", updateCols, updatePaginator)
  })

  useEffect(() => {
    if (selectedOption.value === "All") {
      setSortedResources(resources)
    } else {
      setCurrentPage(1)
      setSortedResources(
        resources.filter(
          post =>
            post?.node?.category[0]?.title.includes(selectedOption.value) ||
            post?.node?.category[1]?.title.includes(selectedOption.value) ||
            post?.node?.category[2]?.title.includes(selectedOption.value)
        )
      )
    }
  }, [selectedOption, resources])

  const handleFilterParams = useCallback(() => {
    const noParam = !selectedOption.value || selectedOption.value === "All"
    const params = noParam
      ? ""
      : `category=${selectedOption?.value?.replaceAll("&", "%26")}`

    const paramsString = params !== "" ? "?" + params : ""

    setFilterParams(paramsString)
  }, [selectedOption])

  useLayoutEffect(() => {
    handleFilterParams()
  }, [handleFilterParams])

  useEffect(() => {
    pushParams(filterParams)
  }, [filterParams])

  return (
    <CardDeckWrapper
      id="resource-hub-card-deck"
      style={{ fontFamily: theme.font.primary }}
    >
      <SortGroupContainer>
        <SortItemWrapper>
          <div>Categories</div>
        </SortItemWrapper>
        <br></br>
        <CategorySelectContainer>
          <CustomSelect
            options={selectCompOptions}
            defaultValue="All"
            value={selectedOption}
            onChange={setSelectedOption}
            placeholder="Select..."
          />
        </CategorySelectContainer>
      </SortGroupContainer>
      <CardDeckGrid>
        {!twoCols && !oneCol && paginatedPosts.slice(0, 12)}
        {twoCols && paginatedPosts.slice(0, 6)}
        {oneCol && paginatedPosts.slice(0, 4)}
      </CardDeckGrid>
      {insetComponent && paginatedPosts.length > 6 && (
        <InsetComponent
          paginatedPosts={paginatedPosts}
          twoCols={twoCols}
          oneCol={oneCol}
          component={insetComponent}
        />
      )}
      <CardDeckGrid>
        {!twoCols && !oneCol && paginatedPosts.slice(12, 24)}
        {twoCols && paginatedPosts.slice(6, 12)}
        {oneCol && paginatedPosts.slice(4, 8)}
      </CardDeckGrid>
      <ComponentPagination
        current={currentPage}
        total={sortedResources.length}
        pageSize={maxPostsPerPage}
        func={setCurrentPage}
      />
      <ScrollBtnWrapper showScrollToTop={showScrollToTop} onClick={scrollToTop}>
        <Button ctaVariant="secondary">Back To Top</Button>
      </ScrollBtnWrapper>
    </CardDeckWrapper>
  )
}

ResourceHubCardDeck.displayName = "ResourceHubCardDeck"

const CardDeckWrapper = styled.div`
  margin-top: 20px;
  padding: 96px 60px 140px 60px;
  @media (max-width: 992px) {
    padding: 80px 40px;
  }
  @media (max-width: 576px) {
    padding: 60px 20px;
  }
  .inset {
    width: 100vw;
    margin-left: -60px;
    margin-bottom: 64px;
    @media (max-width: 992px) {
      margin-left: -40px;
    }
    @media (max-width: 576px) {
      margin-left: -20px;
    }
  }
`

const SortGroupContainer = styled.div`
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 0 auto;
  width: 100%;
  max-width: 1170px;
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
`

const SortItemWrapper = styled.div`
  padding: 0px 32px 0px 0px;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #1b1b1b;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.1px;
  @media (max-width: 768px) {
    padding: 0px 16px 0px 0px;
  }
  @media (max-width: 576px) {
    font-size: 22px;
    line-height: 30px;
  }
`

const CategorySelectContainer = styled.div`
  width: 370px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const CardDeckGrid = styled.div`
  padding-bottom: 64px;
  margin: 0 auto;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 1170px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
    max-width: 770px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    max-width: 506px;
  }
`

const ScrollBtnWrapper = styled.div`
  position: sticky;
  text-align: right;
  bottom: 20px;
  margin-top: -45px;
  transition: opacity 400ms ease, visibility 400ms ease;
  width: fit-content;
  float: right;
  button {
    transition: filter 200ms ease;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px !important;
    line-height: 10px !important;
    padding: 14px 15px !important;
    font-weight: 700;
    min-width: unset !important;
    height: unset !important;
  }
  @media (max-width: 768px) {
    margin-top: -100px;
  }
  ${({ showScrollToTop }) => `
    visibility: ${showScrollToTop ? "visible" : "hidden"};
    opacity: ${showScrollToTop ? "100%" : "0%"};
  `}
`
