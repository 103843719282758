// Blog:
// Links to internal Blog page

// Newsroom + Knowledge Cetner:
// Links to internal Newsroom or Knowledge Center page

// Tile:
// Links to CTA link

// Webinar + Guide:
// if there is a form OR no specified external URL (regardless of form), the slug will be internal
// if there is a specified external URl AND no form, the slug will be the external URL

// Infographic + Video:
// if there is a specified external URL, the slug will be the external URL
// if there is NOT a specified external URL, the slug will be internal

// Podcasts:
// if there is content in the podcastEmbed field, the slug will be internal.
// if there is NOT content in the podcastEmbed field, the slug will be external

// Case Studies:
// the slug is ALWAYS external for Case Studies

export const getCardSlug = post => {
  let typeName
  let externalUrl
  let internalSlug
  let postCategory
  let pdfEmbedUrl

  if (post?.node) {
    typeName = post?.node?.__typename
    externalUrl = post?.node?.externalUrl
    internalSlug = post?.node?.slug
    pdfEmbedUrl = post?.node?.pdfFile?.url
    if (post?.node?.category) {
      postCategory = post?.node?.category[0]?.title.toLowerCase()
    }
  } else {
    typeName = post?.__typename
    externalUrl = post?.externalUrl
    internalSlug = post?.slug
    pdfEmbedUrl = post?.pdfFile?.url
    if (post?.category) {
      postCategory = post?.category[0]?.title.toLowerCase()
    }
  }

  if (typeName === "ContentfulBlogPost") {
    return `/blog/${internalSlug}`
  }
  if (typeName === "ContentfulNewsroomPost") {
    return `/newsroom/${internalSlug}`
  }
  if (typeName === "ContentfulKnowledgeCenterPost") {
    return `/article/${internalSlug}`
  }
  if (typeName === "ContentfulComponentTile") {
    return `/${post?.ctaLink}`
  }
  if (typeName === "ContentfulResource") {
    if (postCategory === "webinar") {
      if (post?.form || !externalUrl) {
        return `/resource/${postCategory}/${internalSlug}`
      } else if (externalUrl) {
        return externalUrl
      }
    } else if (postCategory === "infographic" || postCategory === "video") {
      if (externalUrl) {
        return externalUrl
      } else {
        return `/resource/${postCategory}/${internalSlug}`
      }
    } else if (postCategory === "podcast") {
      if (
        post?.node?.podcastEmbed?.podcastEmbed ||
        post?.podcastEmbed?.podcastEmbed
      ) {
        return `/podcast/${internalSlug}`
      } else {
        return externalUrl
      }
    } else if (postCategory === "case study") {
      return externalUrl
    } else if (postCategory === "guide") {
      if (pdfEmbedUrl || post?.form || !externalUrl) {
        return `/resource/${postCategory}/${internalSlug}`
      } else if (externalUrl) {
        return externalUrl
      }
    }
  }
}
