import React from "react"
import { Layout } from "@components/Layout"
import ConversionPanel from "@components/ConversionPanel/ConversionPanel"
import { graphql } from "gatsby"
import Seo from "@components/Seo/Seo"
import HeroResourceHub from "@components/Hero/HeroResourceHub/index.jsx"
import { ResourceHubCardDeck } from "@components/Resources/ResourceHubCardDeck"

export default function ResourceHubPage({ data }) {
  const { contentfulPage, allContentfulResource } = data
  const hero = contentfulPage?.components[0]
  const conversionPanelOne = contentfulPage?.components[1]
  const conversionPanelTwo = contentfulPage?.components[2]
  const seo = contentfulPage?.pageSeo
  const resourcePosts = allContentfulResource?.edges

  const categories = resourcePosts.reduce((acc, resource) => {
    const categoryTitle = resource?.node?.category[0]?.title
    if (categoryTitle && !acc.includes(categoryTitle)) {
      acc.push(categoryTitle)
    }
    return acc
  }, [])

  return (
    <Layout>
      <Seo
        title={seo?.seoTitle}
        description={seo?.seoDescription}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        metaTags={seo?.metaTags}
      />
      <HeroResourceHub component={hero} posts={resourcePosts} />
      <ResourceHubCardDeck
        resources={resourcePosts}
        headingSize="H2"
        insetComponent={conversionPanelOne}
        categories={categories}
      />
      {conversionPanelTwo && <ConversionPanel component={conversionPanelTwo} />}
    </Layout>
  )
}

export const resourceHubQuery = graphql`
  query resourceHubPageQuery {
    contentfulPage(internalName: { eq: "Resource Hub" }) {
      id
      pageSeo {
        graphImage {
          description
          title
          gatsbyImageData
          file {
            url
          }
        }
        seoTitle
        seoDescription
        canonical
        noIndex
        metaTags
      }
      components {
        ... on ContentfulComponentConversionPanel {
          id
          textAlign
          alignContent
          background
          kicker
          callsToAction {
            contentful_id
            id
            internalLink
            label
            type
            link
            variant
          }
          headingSize
          heading
          noTopPadding
          noBottomPadding
          subheading {
            raw
          }
          type
        }
        ... on ContentfulComponentHero {
          __typename
          id
          type
          background
          alignImage
          subheading {
            raw
          }
          kicker
          heading
          headingSize
          callsToAction {
            contentful_id
            id
            internalLink
            label
            type
            link
            variant
          }
          featuredImage {
            gatsbyImageData
            file {
              url
              contentType
            }
          }
        }
      }
    }

    allContentfulResource(
      sort: { fields: publishDate, order: DESC }
      filter: { id: { ne: "f49ed92d-04a1-53ac-a16f-1a7cec35c611" } }
    ) {
      edges {
        node {
          __typename
          id
          title
          slug
          publishDate
          externalUrl
          category {
            title
            backgroundColor
            fontColor
          }
          featuredImage {
            gatsbyImageData
            file {
              url
            }
          }
          infographicImage {
            gatsbyImageData
            file {
              fileName
              url
            }
          }
          form {
            marketoFormId
            background
          }
          description {
            description
          }
          podcastEmbed {
            podcastEmbed
          }
          body {
            raw
          }
          pdfFile {
            url
          }
        }
      }
    }
  }
`
