import styled from "styled-components"

export const FeaturedItemWrapper = styled.div`
  background: white;
  max-width: 1170px;
  width: calc(100vw - 222px);
  margin: 0 24px 48px 24px;
  border-radius: 6px;
  height: 440px;
  transition: all 0.2s ease-in;
  a {
    text-decoration: none;
  }
  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
    @media (max-width: 768px) {
    width: calc(100vw - 60px);
    height: 100%;
    height: 326px;
  }
`

export const Content = styled.div`
  font-family: Roboto, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI";
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`

export const FeaturedText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px;
  min-width: 400px;
  max-width: 500px;
  border: 1px solid #E4E7EC;
  border-radius: 6px 0 0 6px;
  @media (max-width: 576px) {
    min-width: 100%;
  }
  .item-category {
    padding: 5px 10px;
    width: fit-content;
    display: inline-block;
    color: #1B1B1B;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    border-radius: 35px;
  }
  .item-title {
    font-size: 32px!important;
    font-weight: 600;
    margin: 12px 0 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 576px) {
      font-size: 28px!important;
    }
  }
  .item-description {
    font-size: 16px;
    line-height: 150%;
    margin: 10px 0 0!important;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 768px) {
      -webkit-line-clamp: 2;
    }
    @media (max-width: 576px) {
      display: none;
    }
  }
  .text-link{
    text-decoration: none;
    color: #0022B7;
    font-weight: 700;
    transition: color 250ms ease;
    &:hover {
      color: #5260FF;
      cursor: pointer;
    }
  }
`

export const FeaturedPhoto = styled.div`
  max-width: 100%;
  height: 100%;
  display: inline-block;
  overflow: hidden;
  border-radius: 0 6px 6px 0;
  @media (max-width: 768px) {
    display: none;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
  img {
    object-fit: cover;
    max-width: 100%;
    min-height: 100%;
  }
`