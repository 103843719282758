import React, { useMemo } from "react"
import Select from "react-select"

import { color } from "../../atoms/colors"

const CustomSelect = ({
  options,
  defaultValue,
  value,
  onChange,
  placeholder,
  className,
}) => {
  const selectCustomStyles = useMemo(
    () => ({
      control: (provided, state) => ({
        ...provided,
        padding: "16px 24px",
        borderRadius: "40px",
        borderWidth: "1.5px ",
        borderStyle: "solid",
        borderColor: state.isFocused
          ? `${color.common.hoverBlue}`
          : `${color.grey[300]}`,
        boxShadow:
          state.isFocused &&
          "0px 0px 0px 3px #C8C8FF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        "&:hover": {
          borderColor: `${color.common.hoverBlue}`,
          cursor: "pointer",
        },
      }),
      valueContainer: provided => ({
        ...provided,
        padding: "0",
      }),
      singleValue: provided => ({
        ...provided,
        padding: "0px",
        margin: "0px",
        color: `${color.common.black}`,
      }),
      input: provided => ({
        ...provided,
        padding: "0",
        margin: "0",
        minWidth: "unset",
        "::after": {
          width: "0",
          minWidth: "0",
        },
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: "0",
        transition: "transform ease 150ms",
        transform: state.isFocused && "rotate(180deg)",
        color: `${color.primary[800]}`,
        "&:hover": {
          color: `${color.primary[800]}`,
        },
      }),
      menu: provided => ({
        ...provided,
        borderRadius: "16px",
        border: `1.5px solid ${color.grey[300]}`,
        boxShadow: "0px 20px 30px 0px rgba(16, 24, 40, 0.05)",
      }),
      menuList: provided => ({
        ...provided,
        borderRadius: "14.5px",
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor:
          state.isFocused || state.isSelected
            ? `${color.grey[100]}`
            : "transparent",
        color: state.isSelected
          ? `${color.primary[800]}`
          : `${color.common.black}`,
        fontWeight: state.isSelected ? "600" : "400",
        padding: "8px 24px",
        cursor: "pointer",
        "&:active": {
          backgroundColor: `${color.grey[100]}`,
          fontWeight: "600",
        },
      }),
    }),
    []
  )

  return (
    <Select
      styles={selectCustomStyles}
      options={options}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      blurInputOnSelect={true}
      isSearchable={false}
      className={className ? className : null}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  )
}

export default CustomSelect
