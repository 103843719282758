import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Slider from "react-slick"
import { Heading, TextMono, Text } from "@atoms"
import { Link } from "gatsby"
import HeroFeaturedPost from "@components/Hero/HeroResourceHub/components/HeroFeaturedPost"
import {
  HeroContainer,
  HeroTop,
  HeroBottom,
  HeroTitleBox,
  HeroBreadcrumbs,
  HomeCrumb,
} from "./styles/HeroResourceHub.styled"
import OptimizedImage from "../../../utils/optimizedImage"

import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

export default function HeroResourceHub({ component, posts }) {
  const postData = posts.slice(0, 3).map(item => item.node)

  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    // autoplay: true,
    draggable: true,
    swipeToSlide: true,
    speed: 1000,
    variableWidth: true,
    nextArrow: (
      <div>
        <img src="/icons/pageButtonRight.svg" alt="icon" />
      </div>
    ),
    prevArrow: (
      <div>
        <img src="/icons/pageButtonLeft.svg" alt="icon" />
      </div>
    ),
  }

  const kicker = component?.kicker ? component.kicker : null
  const heading = component?.heading ? component.heading : null
  const subheading = component?.subheading ? component.subheading.raw : null

  return (
    <HeroContainer data-comp={HeroResourceHub.displayName}>
      <HeroTop backgroundImage={component.featuredImage}>
        <OptimizedImage
          className="background"
          image={component?.featuredImage?.gatsbyImageData}
          src={component?.featuredImage?.file?.url}
          alt=""
          loading="eager"
        />
        <HeroBreadcrumbs>
          <Link style={{ textDecoration: "none" }} href="/">
            <HomeCrumb
              id="resource-hub--home-crumb"
              style={{
                textDecoration: "none",
                color: "white",
                transition: "color 200ms ease",
              }}
            >
              Home
            </HomeCrumb>
          </Link>
          <img src="/icons/chevron-right-white.svg" alt="icon" />
          <div style={{ fontWeight: 700, cursor: "pointer" }}>{heading}</div>
        </HeroBreadcrumbs>
        <HeroTitleBox>
          {kicker && (
            <TextMono style={{ color: "#69D1F0", paddingBottom: "12px" }}>
              {kicker}
            </TextMono>
          )}
          {heading && (
            <Heading
              headingSize={component.headingSize}
              style={{ color: "white", paddingBottom: "16px" }}
            >
              {heading}
            </Heading>
          )}
          {subheading && (
            <Text
              headingSize={component.headingSize}
              style={{ color: "white" }}
            >
              {documentToReactComponents(JSON.parse(subheading))}
            </Text>
          )}
        </HeroTitleBox>
      </HeroTop>
      <HeroBottom>
        <Slider {...sliderSettings}>
          {postData.map((item, index) => (
            <HeroFeaturedPost key={index} item={item} />
          ))}
        </Slider>
      </HeroBottom>
    </HeroContainer>
  )
}

HeroResourceHub.displayName = "HeroResourceHub"
