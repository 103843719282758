import styled from "styled-components"

export const HeroContainer = styled.div`
  font-family: Roboto;
  margin-top: 100px;
  .slick-slider {
    z-index:100;
    margin-top: -180px;
    .slick-list {
      @media (max-width: 768px) {
        max-height: 400px;
      }
    }
    .slick-dots {
      margin-top: 15px;
    }
    .slick-dots li button:before {
      font-size: 10px;
    }
    .slick-arrow {
      width: 48px;
      height: 48px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      z-index: 50;
      &:before {
        content: none;
      }
      img {
        width: 48px;
      }
      &.slick-prev {
        left: calc(50% - 200px);
        top: calc(50% + 255px);
        z-index: 10;
        &:hover {
          filter: brightness(85%);
        }
        @media (max-width: 768px) {
          top: calc(50% + 215px);
        }
        @media (max-width: 576px) {
          display: none!important;
        }
      }
      &.slick-next {
        right: calc(50% - 200px);
        top: calc(50% + 255px);
        &:hover {
          filter: brightness(85%);
        }
        @media (max-width: 768px) {
          top: calc(50% + 215px);
        }
        @media (max-width: 576px) {
          display: none!important;
        }
      }
    }
  }
`

export const HeroTop = styled.div`
  position: relative;
  background-image: linear-gradient(180deg, #090069 23.61%, rgba(9, 0, 105, 0.2) 98.49%);
  height: 500px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  .background {
    position: absolute;
    height: 100%;
    width: auto;
    z-index: -1;
  }
`

export const HeroBottom = styled.div`
  z-index: 100;
`

export const HeroTitleBox = styled.div`
  text-align: center;
  margin: 110px 0 70px 0;
`

export const HeroBreadcrumbs = styled.div`
  position: absolute;
  display: flex;
  padding: 24px 60px;
  width: 100%;
  left: 50%;
  top: 0;
  color: white;
  max-width: 1300px;
  transform: translateX(-50%);
  & > * {
    margin: 0 10px 0 0;
  }
`

export const HomeCrumb = styled.span`
  &:hover {
    color: #DDDDDD !important;
  }
`